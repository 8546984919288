<template>
  <TablePagination tag="ul" v-bind:body.sync="history" v-bind:params="params" :class_name="(position >= 2) ? 'team-p' : ''">
    <template v-slot="page">
      <HistoryItem v-bind:item="page" v-bind:position="position"></HistoryItem>
    </template>

  </TablePagination>
</template>

<script>
    import TablePagination from "../../modules/TablePagination";
    export default {
        name: 'History',
        data: function () {
            return {
                dialog: false
            }
        },
        props: {
            history: {
                type: Object
            },
            params: {
                type: Object
            },
            position: {
                type: Number
            }
        },
        created() {

        },
        methods: {


        },
        components: {
            HistoryItem: () => import('./HistoryItem.vue'),
            // HistoryList,
            TablePagination,
        }
    }
</script>

<style>

</style>
